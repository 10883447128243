@import url(https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css);
@media only screen and (max-width: 768px) {
    input[type="range"] {
        position: relative;
        left: 0px;
        width: 5em;
    }

    #player-timer-col {
        position: relative;
        left: 50px;
    }
}
@media only screen and (min-width: 600px) {}
   
/* ******************************************************
    Pgae Loaders Css                                    * 
******************************************************* */
.progress-bar-loader-fill {
    background-color: #AB6DFA !important;
    height: 100%;
}

.progress {
    border-radius: 20px;
}

/* ******************************************************
      Bar Loader                                    * 
  ******************************************************* */
/* .xUwyn > div {
      border-radius: 20px !important;
    } */
.iqtOwM>div {
    border-radius: 20px !important;
    background: #FFFF !important;
}


/* ******************************************************
    Player timeline Input                                   * 
  ******************************************************* */
/* input[type="range"] {
    -webkit-appearance: none;
    height: 5px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.418);
    background-image: linear-gradient(90deg, white, white 17%, white 34%, white 51%, white 68%, white 85%, white) !important;
    background-size: 40%;
    background-repeat: no-repeat;
    animation: progress-animation 2s linear infinite;
    position: relative;
    left: 0px;
    width: 45em;
  } */

/* } */

/* ******************************************************
   Slide Up                                  * 
  ******************************************************* */
.slider {
    overflow-y: hidden;
    max-height: 500px;
    /* approximate max height */

    transition-property: all;
    transition-duration: .5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slider.closed {
    max-height: 0;
}


/* ******************************************************
  Mobile Detail floating up and down                       * 
  ******************************************************* */



/* ******************************************************
  Help Tab content                       * 
  ******************************************************* */
/* Style the tab */
.tab {
    overflow: hidden;
}

/* Style the buttons that are used to open the tab content */
.tab button {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
}

/* Change background color of buttons on hover */
.tab button:hover {
    /* background-color: #ddd; */
}

/* Create an active/current tablink class */
.tab button.active {
    border-bottom: solid #C399F8 2px;
    color: #C399F8 !important;
}

.menu-tab div.active {
    color: #C399F8 !important;
}

.nav-tab div.active {
    color: #C399F8 !important;
    border-bottom: solid #C399F8 2px !important
}

.cat-tab div.active>p {
    border: solid #C399F8 1px !important;
    color: #C399F8 !important;
}

/* Style the tab content */
.tabcontent {
    display: none;
    padding: 6px 12px;
    /* border: 1px solid #ccc; */
    /* border-top: none; */
}

.menu-tabcontent {
    display: none;
}

.nav-tabcontent {
    display: none;
}

/* ******************************************************
  toggle switch button desktop main settings                      * 
  ******************************************************* */

.form-switch .form-check-input:checked[type=checkbox]:after {
    background-color: #C399F8 !important;
}

.form-check-input[type=checkbox]:checked {
    background-color: rgba(195, 153, 248, 0.08) !important;
    ;
}

/* ******************************************************
  toggle switch button desktop main settings      
  sc-eCImPb dAIa-DJ vh-100                * 
  ******************************************************* */
.iconBtnStyle {
    padding: 1px 3px 0px 3px;
    font-size: 45px !important;
    /* color: #00800000;
    border: solid 1px gray; */
}

.iconBtnStyle>i {
    font-size: 30px !important;
    margin: 0px 7px 0px 6px !important;
}

@media (max-width: 992px) {
    .iconBtnStyle {
        font-size: 50px !important;
    }

    .iconBtnStyle>i {
        margin: 0px 10px 0px 12px !important;
    }
}

/* ******************************************************
  Toastify              * 
  ******************************************************* */
.Toastify__toast-theme--dark {
    background: rgba(255, 255, 255, 0.87) !important;
    border-radius: 4px;
    color: black !important;
}

/* ******************************************************
  captureOverlay            * 
  ******************************************************* */

.make-cat-pointer {
    cursor: pointer;
}

.recent-holograms {
    position: relative;
    top: -80px;
}


.sc-cidDSM>div>div>div>div>div.owl-item {
    /* width: unset !important; */
    /* margin-right: 0 !important; */
}

iframe {
    position: static !important;
    z-index: -1000 !important;
    display: none !important
}

a-assets, a-scene audio, a-scene img, a-scene video {
    display: block !important;
    position: absolute;
    width: 100%;

}

.sc-bdvvtL {
    background-color: none;
}

/* .a-canvas{
    z-index: -1;
  } */

/* input[type="range"] {
    position: relative;
    left: 0px;
    width: 45em;
  } */


.record_btn_capture {
    font-size: 4rem !important;
    text-shadow: 2px 1px #0000;
    position: relative;
    top: -20px;
    left: -25px;
    box-shadow: none !important;
}

.capture_btn {
    /* font-size: 100px !important; */
    text-shadow: 2px 1px #0000;
    position: relative;
    top: -45px;
    left: -35px;
}

.record_btn_size {
    font-size: 60px !important;
    position: relative;
    top: -25px !important;
    left: -8px !important;
    ;

}

/* tablet */
@media only screen and (min-width: 600px) {
    .capture_btn, .record_btn {
        left: -30px;
    }
}

/* regular phone screen*/
@media only screen and (min-width: 600px) {
    .capture_btn, .record_btn {
        /* top: -20px; */
        /* left: 0px; */
    }
}

.gkvoqU {
    background: #fffffff3 !important;
    top: 20% !important;
    left: 120px !important;
}

#cast_shadow{
/* background-repeat: no-repeat;
background-attachment: fixed;
background-image: linear-gradient(white 10%, black 80%, white 10%);
background-size: cover; */

}
/* #time-line-left{
  
}

#time-line-right{
    
}
@media (max-width: 992px) {
    #time-line-right{
        margin-left: -18px !important;
    }
    #time-line-left{
        margin-left: 95px;
    }
} */
/* ******************************************************
                  Bootstrap Icons                       * 
******************************************************* */

.text-holotch{
  color: #C399F8 !important;
}
.modal-backdrop {
  z-index: 99;
}
.clickable{
  cursor: pointer;
}
.modal-content {
  background-color: unset;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #121212;
}

#play-pause{
  position: relative;
  margin: 0;
  right: 20px;
}

#left-timeline{
  position: relative;
  margin: 0;
  right: 60px;
}

#right-timeline{
  position: relative;
  margin: 0;
  left: 50px;
}

#player_bar{
  background: rgba(18, 18, 18, 0.5);
  border-radius: 8px;
  height: 31px;
  position: relative;
  top: 8px; /*25px*/
}

#expander{
  position: relative;
  margin: 0;
  left: 25px;
}



input[type="range"]::-webkit-slider-thumb {
  border-radius: 50%;
  background: white;
  cursor: ew-resize;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 0;
  height: 0;
}


input[type="range"]::-webkit-slider-thumb {
  height: 12px;
  width: 12px;
}

input[type="range"] {
  position: relative;
  /* left: -120px;
  width: 127%; */
}

#player-timer-col {
  position: relative;
  left: 25px;
}

@media (max-width: 768px) {
  input[type="range"] {
      position: relative;
      left: 0;
      width: 100%;
  }

  #player-timer-col {
      position: relative;
      left: 50px;
  }
}



body {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
}

.no-decor {
  text-decoration: none;
}

/* ******************************************************
   Bootstrap Over-rides         &nbsp;                         * 
******************************************************* */
.text-danger {
  color: #EA4E3D !important;
}

.text-getlike {
  color: #C399F8 !important;
}

.bg-danger {
  background: #EA4E3D !important;
}

.border-danger {
  color: #EA4E3D !important;
}

.floating-up-down {
  -webkit-animation: action 1s infinite alternate;
  animation: action 1s infinite alternate;
}

@-webkit-keyframes action {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
}

@keyframes action {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
}

#ar-section {
  background-color: none;
  background: none;
}

.live-dot{
  font-size: 15px;
  color: #D14636;

}

.expand_btn{
margin-left: 20px;
}
@media (max-width: 992px) {
  .expand_btn{
    margin-left: -10px;
  }
}

#checkchrome_main{
    background-color: #020202;
    height:100vh;
}

.text-holotch{
    color: #C399F8 !important;
    font-family: Roboto;
  }

.centralize {
    margin: auto;
    width: 70%;
    padding: 10px;
    text-align: center;
    margin-top: 100px;
}

.chrome-check{
    background-color: #6b04f1 !important;
    background: #6b04f1 !important;
    color: #FFFFFF !important;
    border-radius:50px;
}
.roboto-text{
    font-family: Roboto !important;
    font-size: 24px !important;
}
